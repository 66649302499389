import React from 'react';

import SpellBookItem from './SpellBookItem/SpellBookItem';

import styles from './SpellBook.css';

const spellBook = (props) => {
  return <div className={styles.HubMenu}>
    {props.applications && props.applications.map(item => {
      return <SpellBookItem
        key={item.id}
        appConfig={item} />;
    })}
  </div>
};

export default spellBook;