import React, { useState } from 'react';

import commonStyles from '../../../CommonStyles.css';
import styles from './SpellBookMenuTrigger.css';

import menu from '../../../../assets/icons/menu.png';
import menuHover from '../../../../assets/icons/menuHover.png';

const spellBookMenuTrigger = (props) => {
  const [state, setState] = useState(
    {
      hover: false,
      focused: false
    });

  return <a onClick={props.onClick}
    className={[commonStyles.PlaceholderLink, styles.QuickActionMenuButton].join(' ')}
    style={{
      backgroundImage: 'url(' + ((state.hover || state.focused) ? menuHover : menu) + ')'
    }}
    onMouseEnter={() => setState({ ...state, hover: true })}
    onMouseLeave={() => setState({ ...state, hover: false })}
    onFocus={() => setState({ ...state, focused: true })}
    onBlur={() => setState({ ...state, focused: false })} />;
}

export default spellBookMenuTrigger;