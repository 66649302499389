import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import styles from './Popup.css';

const popup = (props) => {

  let [state, setState] = useState({ show: false });

  useEffect(() => {
    if (props.on === 'click') {
      if (state.show) {
        document.addEventListener('click', handleOutsideClick, false);
      } else {
        document.removeEventListener('click', handleOutsideClick, false);
      }
    }

    return () => {
      if (props.on === 'click') {
        document.removeEventListener('click', handleOutsideClick, false);
      }
    }
  });

  const handleOutsideClick = (event) => {
    if (state.show) {
      setState({ show: false });
    }
  }

  const handleTogglePopup = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setState({ show: !state.show });
  }

  const handleShowPopup = (event) => {
    if (!state.show) {
      setState({ show: true });
    }
  }

  const handleHidePopup = (event) => {
    if (state.show) {
      setState({ show: false });
    }
  }

  let popupContentClasses = [styles.PopupContent, props.className];

  if (state.show) {
    popupContentClasses.push(styles.Show);
  }

  return (
    <div className={cx(props.className, [styles.Popup].join(' '))}
      onClick={props.on === 'click' ? handleTogglePopup : null}
      onMouseEnter={props.on === 'hover' ? handleShowPopup : null}
      onMouseLeave={props.on === 'hover' ? handleHidePopup : null}>
      {props.trigger}
      <span className={cx(props.content.props.className, popupContentClasses.join(' '))}>
        {props.content}
      </span>
    </div>
  );
};

export default popup;