export const initRedirectIntercept = () => {
  (function (open) {
    XMLHttpRequest.prototype.open = function (method, url, async, user, pass) {
      this.addEventListener("readystatechange", function () {
        if (this.readyState === this.HEADERS_RECEIVED && this.status === 403) {
          const forbiddenUrl = this.getResponseHeader('location');

          if (forbiddenUrl && window) {
            window.location.href = forbiddenUrl;
          }
        }
      }, false);

      open.call(this, method, url, async || true, user, pass);
    };
  })(XMLHttpRequest.prototype.open);

  const constantMock = window.fetch;
  window.fetch = function () {
    return new Promise((resolve, reject) => {
      constantMock.apply(this, arguments)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          // todo: check for 302 here if needed
          reject(error);
        });
    });
  }
}