import React, { useState } from 'react';

import { DropTarget } from 'react-dnd';

import dragItems from '../../../../constants/dragItems';

import * as actions from '../../../../store/actions/widgetAC';

import commonStyles from '../../../CommonStyles.css';

import assignment from '../../../../assets/icons/assignment.png';
import assignmentHover from '../../../../assets/icons/assignmentHover.png';

const userSpellPlaceholderTarget = {
  hover(props, monitor, component) {
    const isJustOverThisOne = monitor.isOver({ shallow: true });

    const canDrop = monitor.canDrop();
  },

  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return;
    }

    // Obtain the dragged item
    const item = monitor.getItem();

    actions.updateUserDefinedSpells(props.dispatch, props.spellbarConfigEndpointUrl, props.accessToken, props.spellId, {
      appId: item.appConfig.id,
      spellId: item.spellId
    });

    return { moved: true };
  }
};

function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

const userSpellPlaceholder = (props) => {
  const [state, setState] = useState(
    {
      hover: false,
      focused: false
    });

  const dropTargetClasses = [commonStyles.DropTarget];

  if (props.canDrop) {
    dropTargetClasses.push(commonStyles.DropTargetCanDrop);
  }

  return <a className={commonStyles.PlaceholderLink}
    onClick={(event) => {
      props.handleSpellBarItemClick(event);
      if (props.onClick) {
        props.onClick(event);
      }
    }}
    style={{
      backgroundImage: 'url(' + ((state.hover || state.focused) ? assignmentHover : assignment) + ')'
    }}
    onMouseEnter={() => setState({ ...state, hover: true })}
    onMouseLeave={() => setState({ ...state, hover: false })}
    onFocus={() => setState({ ...state, focused: true })}
    onBlur={() => setState({ ...state, focused: false })}>
    {props.connectDropTarget(
      <div className={dropTargetClasses.join(' ')}>
        {props.spellId}
      </div>
    )}
  </a>;
};

export default DropTarget([dragItems.USER_SPELL_ITEM, dragItems.SPELL_BOOK_ITEM], userSpellPlaceholderTarget, dropCollect)(userSpellPlaceholder);