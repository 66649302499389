import React from 'react';

import styles from './SpellDescription.css';

const spellDescription = (props) => {
  return <div className={styles.SpellItemDescription}>
    <div className={styles.SpellItemDescriptionIcon}
      style={{
        backgroundImage: 'url(' + props.appConfig.appIconUrl + ')'
      }}></div>
    <div className={styles.SpellItemDescriptionContent}>
      <div className={styles.SpellItemDescriptionHeader}>
        {props.appConfig.clientName}
      </div>
      <div className={styles.SpellItemDescriptionText}>
        {props.appConfig.description}
      </div>
    </div>
  </div>;
};

export default spellDescription;