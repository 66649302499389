import React from 'react';

import { DragSource } from 'react-dnd';

import dragItems from '../../../../constants/dragItems';

import styles from './SpellBookItem.css';

const spellBookSource = {
  beginDrag(props) {
    return {
      appConfig: props.appConfig
    };
  },

  endDrag(props, monitor) {
    // todo: send request to save spellbar
  }
};

const collect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

const spellBookItem = (props) => {
  return props.connectDragSource(
    <div>
      <a className={styles.HubMenuItem}
        style={{ opacity: props.isDragging ? 0.5 : 1 }}
        href={props.appConfig.appUrl}
        onClick={(event) => {
          event.stopPropagation();
        }}
        title={props.appConfig.description}>
        <div className={styles.HubMenuIcon}
          style={{
            backgroundImage: 'url(' + props.appConfig.appIconUrl + ')'
          }}></div>
        <span className={styles.HubMenuText}>{props.appConfig.clientName}</span>
      </a>
    </div>
  );
};

export default DragSource(dragItems.SPELL_BOOK_ITEM, spellBookSource, collect)(spellBookItem);