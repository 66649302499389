import React from 'react';

import SpellDescription from './SpellDescription/SpellDescription';
import UserSpell from './UserSpell/UserSpell';
import UserSpellPlaceholder from './UserSpellPlaceholder/UserSpellPlaceholder';

import Popup from '../../Popup/Popup';

import commonStyles from '../../CommonStyles.css';
import styles from './UserSpells.css';

const userSpells = (props) => {
  return Array.from(Array(5))
    .map((item, index) => {
      let link = <Popup className={commonStyles.MenuPopup}
        on='click'
        key={index.toString()}
        trigger={<UserSpellPlaceholder
          dispatch={props.dispatch}
          spellbarConfigEndpointUrl={props.spellbarConfigEndpointUrl}
          accessToken={props.accessToken}
          spellId={index + 1}
          handleSpellBarItemClick={props.handleSpellBarItemClick} />}
        content={
          <div className={[commonStyles.MenuPopupContent, styles.PlaceholderOptionsContainer].join(' ')}>
            {props.applications && props.applications.map(item => {
              return <div className={styles.PlaceholderOption}
                onClick={(event) => props.handleDefineUserSpellClick(event, item, index + 1)}
                key={item.id}
                name={item.name}
                title={item.description}
                style={{
                  backgroundImage: 'url(' + item.appIconUrl + ')'
                }}></div>;
            })}
          </div>
        } />;

      if (props.applications && props.userDefinedSpells) {
        const spell = props.userDefinedSpells.find(item => item.spellId === index + 1);

        if (spell) {
          const appConfig = props.applications.find(item => item.id === spell.appId);

          if (appConfig) {
            link = <Popup className={commonStyles.MenuPopup}
              on='hover'
              key={index.toString()}
              trigger={<UserSpell
                dispatch={props.dispatch}
                spellbarConfigEndpointUrl={props.spellbarConfigEndpointUrl}
                accessToken={props.accessToken}
                spellId={index + 1}
                userDefinedSpells={props.userDefinedSpells}
                hoveredSpellId={props.hoveredSpellId}
                appConfig={appConfig}
                onMouseEnter={(spellId) => props.handleSetHoveredSpellId(spellId)}
                onMouseLeave={(spellId) => props.handleSetHoveredSpellId(spellId)} />}
              content={<SpellDescription
                className={[commonStyles.MenuPopupContent, styles.SpellDescriptionPopup].join(' ')}
                appConfig={appConfig} />} />;
          }
        }
      }

      return link;
    })
};

export default userSpells;