import React, { useEffect } from 'react';

import { DragSource, DropTarget } from 'react-dnd';

import dragItems from '../../../../constants/dragItems';

import * as actions from '../../../../store/actions/widgetAC';

import commonStyles from '../../../CommonStyles.css';

const userSpellSource = {
  beginDrag(props) {
    return {
      appConfig: props.appConfig,
      spellId: props.spellId
    };
  },

  endDrag(props, monitor) {
    const item = monitor.getItem();

    if (props.spellId === item.spellId) {
      return;
    }

    actions.updateUserDefinedSpells(props.dispatch, props.spellbarConfigEndpointUrl, props.accessToken, 0, {
      appId: props.appConfig.id,
      spellId: props.spellId
    });
  }
};

const collect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
};

const userSpellTarget = {
  canDrop(props, monitor) {
    const item = monitor.getItem();

    if (props.spellId === item.spellId) {
      return false;
    }

    return true;
  },

  hover(props, monitor, component) {
    const isJustOverThisOne = monitor.isOver({ shallow: true });

    const canDrop = monitor.canDrop();
  },

  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return;
    }

    // Obtain the dragged item
    const item = monitor.getItem();

    if (props.spellId === item.spellId) {
      return;
    }

    // Obtain item dragged over
    const currentItem = component.props;

    actions.updateUserDefinedSpells(props.dispatch, props.spellbarConfigEndpointUrl, props.accessToken, currentItem.spellId, {
      appId: item.appConfig.id,
      spellId: item.spellId
    });

    return { moved: true };
  }
};

function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

const userSpell = (props) => {
  const dropTargetClasses = [commonStyles.DropTarget];

  if (props.canDrop) {
    dropTargetClasses.push(commonStyles.DropTargetCanDrop);
  }

  return props.connectDragSource(
    <a className={commonStyles.PlaceholderLink}
      href={props.appConfig.appUrl}
      style={{ opacity: props.isDragging ? 0.5 : 1 }}
      style={props.hoveredSpellId === props.spellId
        ? {
          backgroundImage: 'url(' + props.appConfig.appIconHoverUrl + ')'
        }
        : {
          backgroundImage: 'url(' + props.appConfig.appIconUrl + ')'
        }}
      onMouseEnter={() => props.onMouseEnter(props.spellId)}
      onMouseLeave={() => props.onMouseEnter(0)}>
      {props.connectDropTarget(
        <div className={dropTargetClasses.join(' ')}>
          {props.spellId}
        </div>
      )}
    </a>
  );
};

export default DropTarget([dragItems.USER_SPELL_ITEM, dragItems.SPELL_BOOK_ITEM], userSpellTarget, dropCollect)
  (DragSource(dragItems.USER_SPELL_ITEM, userSpellSource, collect)(userSpell));