import { createUserManager } from 'redux-oidc';

// default value. Should be overwritten from external
const userManagerConfig = {
  client_id: 'RugWidgetsTest',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  response_type: 'token id_token',
  scope: 'openid profile',
  authority: 'https://auth.loadrpm.com',
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true
};

let userManager = createUserManager(userManagerConfig);

export const initUserManager = (config) => {
  if (config) {
    userManager = createUserManager(config);
  }
}

export { userManager };