import React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'connected-react-router';
import { userManager } from '../shared/userManager';

class Callback extends React.Component {
  render() {
    let callbackUrl = localStorage.getItem('callbackUrl');
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={() => {
          this.props.dispatch(push(callbackUrl ? callbackUrl : '/'));
          localStorage.removeItem('callbackUrl');
        }}
        errorCallback={error => {
          this.props.dispatch(push('/'));
          console.error(error);
        }}>
        {this.props.callbackPageContent}
      </CallbackComponent>
    );
  }
}

export default connect()(Callback);
